import React, { useEffect } from "react";
import SwiperCore, { Pagination } from "swiper";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";
import AOS from "aos";
import "aos/dist/aos.css";
import E1 from "../img/e1.svg";
import E2 from "../img/e2.svg";
import E3 from "../img/e3.svg";
import slider1 from "../img/slider1.png";
import slider2 from "../img/slider3.png";
import slider3 from "../img/slider2.png";

// Import Swiper styles
import "swiper/swiper-bundle.min.css";

// Install Swiper modules
SwiperCore.use([Pagination]);

const EventsSection = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });

    // Swiper Initialization
    const swiper = new SwiperCore(".slides-3", {
      speed: 600,
      loop: true,
      spaceBetween: window.innerWidth < 776 ? 10 : 60,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      breakpoints: {
        320: {
          slidesPerView: 1,
        },
        1000: {
          slidesPerView: 1.1,
        },
        1200: {
          slidesPerView: 1.1,
        },
      },
      slidesPerView: "auto",
      pagination: {
        el: ".swiper-pagination",
        type: "bullets",
        clickable: true,
      },
    });
  }, []);

  return (
    <div id="events" className="events">
      <div className="container-fluid" data-aos="fade-up">
        <div
          className="slides-3 swiper"
          data-aos="fade-up"
          data-aos-delay="100"
        >
          <div className="swiper-wrapper">
            {/* Slide 1 */}
            <div className="swiper-slide event-item d-flex flex-column justify-content-start justify-md-center justify-lg-center">
              <div className="flavors">
                <div className="row justify-content-center align-items-center">
                  <div className="col-lg-6">
                    <h2>Authentic Flavors</h2>
                    <p>
                      At Nine Spices, authenticity is more than a commitment;
                      it's a journey into the rich, diverse flavors of India.
                      Our chefs draw inspiration from the past, presenting a
                      contemporary twist on age-old recipes, ensuring each bite
                      encapsulates the true essence of Indian cuisine.
                    </p>
                    <div className="info-item d-flex align-items-center">
                      <img
                        src={E1}
                        className="img-fluid me-3"
                        alt=""
                        data-aos="zoom-out"
                        data-aos-delay="300"
                      />
                      <div>
                        <h3>
                          Unwavering culinary <br />
                          authenticity focus
                        </h3>
                      </div>
                    </div>
                    <div className="info-item  d-flex align-items-center">
                      <img
                        src={E2}
                        className="img-fluid me-3"
                        alt=""
                        data-aos="zoom-out"
                        data-aos-delay="300"
                      />
                      <div>
                        <h3>
                          Chefs recreate <br /> age-old recipes.
                        </h3>
                      </div>
                    </div>
                    <div className="info-item  d-flex align-items-center">
                      <img
                        src={E3}
                        className="img-fluid me-3"
                        alt=""
                        data-aos="zoom-out"
                        data-aos-delay="300"
                      />
                      <div>
                        <h3>
                          Dishes reflect <br /> enduring techniques
                        </h3>
                      </div>
                    </div>
                  </div>{" "}
                  {/* Closing col-lg-6 div */}
                  <div className="offset-lg-1 col-lg-5">
                    <img
                      src={slider1}
                      className="img-fluid"
                      alt=""
                      data-aos="zoom-out"
                      data-aos-delay="300"
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* Slide 2 */}
            <div className="swiper-slide event-item d-flex flex-column justify-content-start justify-md-center justify-lg-center">
              <div className="flavors">
                <div className="row justify-content-center align-items-center">
                  <div className="col-lg-6">
                    <h2>
                      Use Freshly <br /> Grounded Spices
                    </h2>
                    <p>
                      At Nine Spices, we enhance your culinary journey by
                      freshly grinding each spice to unlock its full potential.
                      Our spice artisans' dedication ensures every meal is rich
                      with aromatic flavors, offering a journey into the heart
                      of Indian culinary heritage with each bite—an
                      unforgettable adventure through diverse, vibrant flavors.
                    </p>
                    <div className="info-item d-flex align-items-center">
                      <img
                        src={E1}
                        className="img-fluid me-3"
                        alt=""
                        data-aos="zoom-out"
                        data-aos-delay="300"
                      />
                      <div>
                        <h3>Unlocking full flavor potential</h3>
                      </div>
                    </div>
                    <div className="info-item  d-flex align-items-center">
                      <img
                        src={E2}
                        className="img-fluid me-3"
                        alt=""
                        data-aos="zoom-out"
                        data-aos-delay="300"
                      />
                      <div>
                        <h3>
                          Carefully selecting, blending, and <br /> grinding
                        </h3>
                      </div>
                    </div>
                    <div className="info-item  d-flex align-items-center">
                      <img
                        src={E3}
                        className="img-fluid me-3"
                        alt=""
                        data-aos="zoom-out"
                        data-aos-delay="300"
                      />
                      <div>
                        <h3>
                          Preserving traditional recipes' <br /> authenticity
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="offset-lg-1 col-lg-5">
                    <img
                      src={slider3}
                      className="img-fluid"
                      alt=""
                      data-aos="zoom-out"
                      data-aos-delay="300"
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* Slide 3 */}

            <div className="swiper-slide event-item d-flex flex-column justify-content-start justify-md-center justify-lg-center">
              <div className="flavors">
                <div className="row justify-content-center align-items-center">
                  <div className="col-lg-7">
                    <h2>Fresh, quality ingredients for body and soul</h2>
                    <p>
                      At Nine Spices, quality is our passion, reflected in
                      top-notch ingredient selection and meticulous sourcing of
                      fresh, local produce. Our chefs create meals to tantalize
                      taste buds and nourish your body and soul, embracing the
                      power of wholesome, unprocessed ingredients for a
                      nutritious and soul-satisfying dining experience.
                    </p>
                    <div className="info-item d-flex align-items-center">
                      <img
                        src={E1}
                        className="img-fluid me-3"
                        alt=""
                        data-aos="zoom-out"
                        data-aos-delay="300"
                      />
                      <div>
                        <h3>Passion for top-quality ingredients</h3>
                      </div>
                    </div>
                    <div className="info-item d-flex align-items-center">
                      <img
                        src={E2}
                        className="img-fluid me-3"
                        alt=""
                        data-aos="zoom-out"
                        data-aos-delay="300"
                      />
                      <div>
                        <h3>Meticulously source fresh, local produce</h3>
                      </div>
                    </div>
                    <div className="info-item d-flex align-items-center">
                      <img
                        src={E3}
                        className="img-fluid me-3"
                        alt=""
                        data-aos="zoom-out"
                        data-aos-delay="300"
                      />
                      <div>
                        <h3>
                          Chefs craft meals to tantalize taste <br /> buds and
                          nourish body and soul
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-5">
                    <img
                      src={slider2}
                      className="img-fluid"
                      alt=""
                      data-aos="zoom-out"
                      data-aos-delay="300"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Pagination */}
          <div className="swiper-pagination"></div>
        </div>
      </div>
    </div>
  );
};

export default EventsSection;
