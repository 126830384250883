import React from "react";
import "bootstrap-icons/font/bootstrap-icons.css";
import Logo from "../img/logo.png";
import Footerlogo from "../img/footerlogo.svg";

function Footer() {
  return (
    <div>
      <footer id="footer" className="footer">
        <div className="container">
          <div className="row gy-3">
            <div className="col-lg-2 col-md-12">
              <div>
                <a href="#">
                  <img src={Footerlogo} alt="" className="img-fluid" />
                </a>
                <p className="mt-3">Indulge in authentic Indian flavors</p>
              </div>
            </div>

            <div className="col-lg-5 offset-lg-2 col-md-12 footer-links">
              <h4>Get In touch</h4>
              <ul>
                <li>
                  <div className="d-flex">
                    <i className="bi bi-envelope icon"></i>
                    <div>
                      <p className="mb-0">info@9spices.com</p>
                    </div>
                  </div>
                </li>
                <li>
                  {/* <div className="d-flex">
                    <i className="bi bi-telephone icon"></i>
                    <div>
                      <p className="mb-0">8956265874</p>
                    </div>
                  </div> */}
                </li>
              </ul>
            </div>

            <div className="col-lg-3 col-md-12 footer-links">
              <div className="social-links d-flex">
                <a
                  href="https://instagram.com/ninespicescuisine"
                  className="instagram"
                >
                  <i className="bi bi-instagram"></i>
                </a>
              </div>
              <p className="mt-3">Follow our social media.</p>
            </div>
          </div>

          <div className="text-start text-lg-center">
            <p>©Copyright 9Spices</p>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
