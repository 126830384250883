import React from "react";

const Notes = () => {
  return (
    <section className="p-0 mb-3">
      <div className="container mt-5">
        <div id="accordion">
          <div className="accordion-item">
            <h2 className="accordion-header mb-3" id="headingOne">
              <button
                className="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                <strong>Note</strong>
              </button>
            </h2>
            <div
              id="collapseOne"
              className="accordion-collapse collapse show"
              aria-labelledby="headingOne"
              data-bs-parent="#accordion"
            >
              <div className="accordion-body">
                <ul>
                  <li>
                    All orders require 72 hour advance notice. Minimum order is
                    $250. Prices for catering orders are for food only.
                  </li>
                  <li>
                    Delivery, set-up and other fees may apply. No delivery fee
                    within 50 miles for orders $1000 and above. Prices are
                    subject to change without notice.
                  </li>
                  <li>
                    Choose from any item on our menu. Each tray holds one item.
                  </li>
                  <li>
                    The number of people each tray will serve will vary based on
                    the number of different items being served. The more options
                    you add, the less you need of each.
                  </li>
                  <li>
                    Half trays (about 80-90 oz.) serve approx. 10-12 people.
                    Full trays (about 205-210 oz.) serve approx. 20-25 people.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Notes;
