import React, { useEffect, useState } from "react";
import CartCard from "./CartCard";
import emailjs from "emailjs-com";
import axios from "axios";

const PlaceOrderSection = ({
  cart,
  setCart,
  subTotal,
  total,
  onUpdateQuantity,
  onSelectChange,
  onDelete,
  onRefresh,
}) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNumber: "+1",
    deliveryDate: "",
    deliveryTime: "",
    address: "",
    zipCode: "",
    city: "",
    state: "",
    specialInstructions: "", // Added field for special instructions
  });

  const [errorMessage, setErrorMessage] = useState(null);

  const [formErrors, setFormErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Check if the phone number already contains the '+1' prefix
    let newValue;
    if (name === "phoneNumber") {
      // Remove any existing '+1' prefix before updating the value
      newValue = value.replace(/^\+1\s*/, "");
      // Add '+1' prefix to the cleaned phone number
      newValue = `+1 ${newValue}`;
    } else {
      newValue = value;
    }

    setFormData({
      ...formData,
      [name]: newValue,
    });
  };

  useEffect(() => {
    // This effect runs whenever 'total' changes
    const numericTotal = parseFloat(total); // Convert total to a numeric value

    if (numericTotal >= 250) {
      setErrorMessage(null); // Clear the error message when the total increases
    }
  }, [total]);

  const validateForm = () => {
    const errors = {};

    // Validation for Name
    if (!formData.name.trim()) {
      errors.name = "Name is required";
    }

    // Validation for Email
    if (!formData.email.trim()) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Invalid email address";
    }

    // Validation for Phone Number
    if (!formData.phoneNumber.trim()) {
      errors.phoneNumber = "Phone number is required";
    }
    if (
      !(formData.phoneNumber.trim().length > 3) // Ensure phone number has a minimum length of 5
    ) {
      errors.phoneNumber = "Phone number is required";
    }

    // // Validation for Delivery Date
    // if (!formData.deliveryDate.trim()) {
    //   errors.deliveryDate = "Delivery date is required";
    //   // Add more specific validation rules for the date if needed
    // }

    // // Validation for Delivery Time
    // if (!formData.deliveryTime.trim()) {
    //   errors.deliveryTime = "Delivery time is required";
    //   // Add more specific validation rules for the time if needed
    // }

    // // Validation for Address
    // if (!formData.address.trim()) {
    //   errors.address = "Address is required";
    // }

    // // Validation for City
    // if (!formData.city.trim()) {
    //   errors.city = "City is required";
    // }

    // // Validation for State
    // if (!formData.state.trim()) {
    //   errors.state = "State is required";
    // }

    // Add more validation rules for other fields...

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async () => {
    // if (total < 250) {
    //   setErrorMessage(
    //     "Order total must be at least $250. Please add more items to your cart."
    //   );
    //   return; // Do not proceed with order submission
    // }

    if (validateForm()) {
      const emailData = {
        to_email: formData.email,
        from_name: "9Spices",
        subject: "Order Confirmation",
        name: formData.name,
        email: formData.email,
        phoneNumber: formData.phoneNumber,
        deliveryDate: formData.deliveryDate,
        deliveryTime: formData.deliveryTime,
        address: formData.address,
        zipCode: formData.zipCode,
        city: formData.city,
        state: formData.state,
        specialInstructions: formData.specialInstructions,
        cart: cart,
        subTotal: subTotal,
        total: total,
        deliveryFee: 25,
      };

      try {
        // Replace 'https://localhost:8000/order' with the correct API endpoint
        const response = await axios.post(
          "https://be.9spices.com/order",
          // "http://localhost:8000/order",
          emailData
        );
        alert("Order Confirmation sent successfully");
        window.location.reload();

        // Add logic here to handle response, update UI, etc.
      } catch (error) {
        console.error("Error submitting order:", error);
        // Handle error, show user a message, etc.
      }
    }
  };

  const getCurrentDate = () => {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 3); // Add 3 days

    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  };

  return (
    <section className="placeorder-background">
      <div className="container">
        <div className="place-order">
          <div className="row">
            <div className="col-lg-7">
              <h4>
                To customize order email:
                <br />
                ORDER at order@9spices.com
              </h4>
            </div>
            <div className="col-lg-5">
              <div className="d-flex justify-content-center align-items-center">
                <div className="me-5">
                  <h5>
                    {cart.length}
                    <br />
                    Items added
                  </h5>
                </div>
                <div>
                  <button
                    type="button"
                    className="btn place-order-btn"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                  >
                    Place an Order
                  </button>
                  <div
                    className="modal fade "
                    id="exampleModal"
                    tabIndex="-1"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog modal-xl modal-dialog-centered">
                      <div className="modal-content">
                        <div className="modal-header">
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          ></button>
                        </div>
                        <div className="modal-body">
                          <div className="row">
                            <div className="col-lg-7">
                              <div className="modal-form">
                                <h4>Enter your details</h4>
                                <form className="mt-5">
                                  <div className="form-floating mb-4">
                                    <label htmlFor="floatingInput">Name*</label>
                                    <input
                                      type="text"
                                      className={`form-control ${
                                        formErrors.name ? "is-invalid" : ""
                                      }`}
                                      id="name"
                                      placeholder="Ali Lee"
                                      value={formData.name}
                                      name="name"
                                      onChange={handleInputChange}
                                    />
                                    {formErrors.name && (
                                      <div className="invalid-feedback">
                                        {formErrors.name}
                                      </div>
                                    )}
                                  </div>
                                  <div className="row mb-4">
                                    <div className="col-6">
                                      <div className="form-floating">
                                        <input
                                          type="email"
                                          className={`form-control ${
                                            formErrors.email ? "is-invalid" : ""
                                          }`}
                                          id="email"
                                          placeholder="ali@gmail.com"
                                          value={formData.email}
                                          name="email"
                                          onChange={handleInputChange}
                                        />
                                        {formErrors.email && (
                                          <div className="invalid-feedback">
                                            {formErrors.email}
                                          </div>
                                        )}
                                        <label htmlFor="floatingInput">
                                          Email ID*
                                        </label>
                                      </div>
                                    </div>
                                    <div className="col-6">
                                      <div className="form-floating">
                                        <input
                                          type="text"
                                          className={`form-control ${
                                            formErrors.phoneNumber
                                              ? "is-invalid"
                                              : ""
                                          }`}
                                          id="floatingInput"
                                          placeholder="Enter your phone number"
                                          value={formData.phoneNumber}
                                          name="phoneNumber"
                                          onChange={handleInputChange}
                                        />
                                        {formErrors.phoneNumber && (
                                          <div className="invalid-feedback">
                                            {formErrors.phoneNumber}
                                          </div>
                                        )}
                                        <label htmlFor="floatingInput">
                                          Phone no.*
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row mb-4">
                                    <div className="col-6">
                                      <div className="form-floating">
                                        <input
                                          type="date"
                                          className={`form-control ${
                                            formErrors.deliveryDate
                                              ? "is-invalid"
                                              : ""
                                          }`}
                                          id="floatingInput"
                                          placeholder="DD/MM/YYYY"
                                          value={formData.deliveryDate}
                                          name="deliveryDate"
                                          onChange={handleInputChange}
                                          min={getCurrentDate()}
                                        />
                                        {formErrors.deliveryDate && (
                                          <div className="invalid-feedback">
                                            {formErrors.deliveryDate}
                                          </div>
                                        )}
                                        <label htmlFor="floatingInput">
                                          Delivery Date
                                        </label>
                                      </div>
                                    </div>
                                    <div className="col-6">
                                      <div className="form-floating">
                                        <input
                                          type="time"
                                          className={`form-control ${
                                            formErrors.deliveryTime
                                              ? "is-invalid"
                                              : ""
                                          }`}
                                          id="floatingInput"
                                          placeholder="00:00 PM"
                                          value={formData.deliveryTime}
                                          name="deliveryTime"
                                          onChange={handleInputChange}
                                        />
                                        {formErrors.deliveryTime && (
                                          <div className="invalid-feedback">
                                            {formErrors.deliveryTime}
                                          </div>
                                        )}
                                        <label htmlFor="floatingInput">
                                          Delivery Time
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="form-floating mb-4">
                                    <input
                                      type="text"
                                      className={`form-control ${
                                        formErrors.address ? "is-invalid" : ""
                                      }`}
                                      id="floatingInput"
                                      placeholder="Address"
                                      value={formData.address}
                                      name="address"
                                      onChange={handleInputChange}
                                    />
                                    {formErrors.address && (
                                      <div className="invalid-feedback">
                                        {formErrors.address}
                                      </div>
                                    )}
                                    <label htmlFor="floatingInput">
                                      Enter Address
                                    </label>
                                  </div>
                                  <div className="row mb-4">
                                    <div className="col-4">
                                      <div className="form-floating">
                                        <input
                                          type="text"
                                          className={`form-control ${
                                            formErrors.zipCode
                                              ? "is-invalid"
                                              : ""
                                          }`}
                                          id="floatingInput"
                                          pattern="\d{5}"
                                          title="Enter a 5-digit ZIP code"
                                          placeholder="ZIP code"
                                          value={formData.zipCode}
                                          name="zipCode"
                                          onChange={handleInputChange}
                                        />
                                        {formErrors.zipCode && (
                                          <div className="invalid-feedback">
                                            {formErrors.zipCode}
                                          </div>
                                        )}
                                        <label htmlFor="floatingInput">
                                          Zip Code
                                        </label>
                                      </div>
                                    </div>
                                    <div className="col-4">
                                      <div className="form-floating">
                                        <input
                                          type="text"
                                          className={`form-control ${
                                            formErrors.city ? "is-invalid" : ""
                                          }`}
                                          id="floatingInput"
                                          placeholder="eg. New York City"
                                          value={formData.city}
                                          name="city"
                                          onChange={handleInputChange}
                                        />
                                        {formErrors.city && (
                                          <div className="invalid-feedback">
                                            {formErrors.city}
                                          </div>
                                        )}
                                        <label htmlFor="floatingInput">
                                          City
                                        </label>
                                      </div>
                                    </div>
                                    <div className="col-4">
                                      <div className="form-floating">
                                        <input
                                          type="text"
                                          className={`form-control ${
                                            formErrors.state ? "is-invalid" : ""
                                          }`}
                                          id="floatingInput"
                                          placeholder="eg. New York"
                                          value={formData.state}
                                          name="state"
                                          onChange={handleInputChange}
                                        />
                                        {formErrors.state && (
                                          <div className="invalid-feedback">
                                            {formErrors.state}
                                          </div>
                                        )}
                                        <label htmlFor="floatingInput">
                                          State
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>

                            <div className="col-lg-5">
                              <div className="scrollable-section">
                                {cart.map((item, index) => (
                                  <CartCard
                                    key={item.id}
                                    item={item}
                                    index={index}
                                    setCart={setCart}
                                    cart={cart}
                                    onUpdateQuantity={onUpdateQuantity}
                                    onSelectChange={onSelectChange}
                                    onDelete={onDelete}
                                  />
                                ))}
                              </div>

                              <div className="order-total">
                                <div className="d-flex justify-content-between">
                                  <h4>Subtotal</h4>
                                  <h4>${subTotal}</h4>
                                </div>

                                <hr className="mt-0" />

                                <div className="d-flex justify-content-between">
                                  <h5>Order Total</h5>
                                  <h4>${total}</h4>
                                </div>
                                {errorMessage && (
                                  <div
                                    className="alert alert-danger"
                                    role="alert"
                                  >
                                    {errorMessage}
                                  </div>
                                )}
                                <div className="d-flex justify-content-end">
                                  {" "}
                                  {/* Use justify-content-end to align to the end (right) */}
                                  <p
                                    className="text-end"
                                    style={{
                                      fontSize: "0.75rem",
                                      color: "#101A24",
                                    }}
                                  >
                                    Note: Delivery Fee, and Sales Tax will be
                                    extra.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="modal-footer">
                          <button
                            type="button"
                            className="btn border-btn "
                            data-bs-dismiss="modal"
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            // data-bs-dismiss="modal"
                            className="btn form-btn"
                            onClick={handleSubmit}
                          >
                            Confirm Order
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PlaceOrderSection;
