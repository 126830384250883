import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import Logo from '../img/logo.png'

function Navbar() {
  return (
    <div>
      <header id="header" className="header fixed-top d-flex align-items-center">
        <div className="container d-flex align-items-center justify-content-between">
          <a href="index.html" className="logo d-flex align-items-center me-auto me-lg-0">
            <img src={Logo} alt="" className="img-fluid" />
          </a>
          <nav id="navbar" className="navbar">
            <ul>
              <li><a href="#about">About</a></li>
              <li><a href="#menu">Menu</a></li>
              <li><a href="#subscription">Subscription</a></li>
            </ul>
          </nav>
          <i className="mobile-nav-toggle mobile-nav-show bi bi-list"></i>
          <i className="mobile-nav-toggle mobile-nav-hide d-none bi bi-x"></i>
        </div>
      </header>
    </div>

  )
}

export default Navbar