import React, { useEffect, useState } from "react";
import "bootstrap-icons/font/bootstrap-icons.css";
import data from "../items.json";
import Tab1 from "../img/tab1.png";
import Tab2 from "../img/tab2.png";
import Tab3 from "../img/tab3.png";
import TabHeader from "../img/tab-header.png";
import TabHalfHeader from "../img/tab-header-one.png";
import Tab4 from "../img/tab4.png";
import Warning from "../img/warning.svg";
import Card from "./Card";
import PlaceOrderSection from "./Placeorder";

function Menu() {
  const [order, setOrder] = useState(data.items);
  let [cart, setCart] = useState([]);
  const [subTotal, setSubTotal] = useState(0);
  const [total, setTotal] = useState(0);
  const [tab, setTab] = useState("tab1");
  const [tab1, setTab1] = useState("subTab3");
  const [tab2, setTab2] = useState("subTab7");
  const [tab3, setTab3] = useState("subTab9");

  useEffect(() => {
    const price = calculateOrder();
    setSubTotal(price);
    setTotal(price);
  }, [cart]);

  const [count, setCount] = useState(0);

  const calculateOrder = () => {
    return cart.reduce(
      (acc, item) =>
        acc +
        (item.fullTrayQuantity * item.fullPrice +
          item.halfTrayQuantity * item.halfPrice),
      0
    );
  };

  // Assume you have a state variable like 'setCart' to update the cart
  const onUpdateQuantity = (itemId, newQuantity, trayType) => {
    // Find the item in the cart
    const updatedCart = cart.map((cartItem) => {
      if (cartItem.id === itemId) {
        // Update the quantity for the specified trayType
        cartItem[`${trayType}Quantity`] = newQuantity;
      }
      return cartItem;
    });

    // Update the cart state
    setCart(updatedCart);
  };

  const onSelectChange = (id, tray) => {
    setCart((prevCart) =>
      prevCart.map((item) => {
        if (item.id === id) {
          // Check if traySize is full and item.tray is half
          if (tray === "full" && item.tray === "half") {
            return { ...item, tray: tray, price: item.price * 2 - 10 };
          }
          // Check if traySize is half and item.tray is full
          else if (tray === "half" && item.tray === "full") {
            return { ...item, tray: tray, price: item.price / 2 + 5 };
          }
          // If traySize is the same, just update traySize without changing the price
          else {
            return { ...item, tray: tray };
          }
        } else {
          return item;
        }
      })
    );
  };

  const onDelete = (id) => {
    setCart((prevCart) => prevCart.filter((item) => item.id !== id));
  };

  const onRefresh = () => {
    setOrder(data.items);
  };
  return (
    <>
      <section id="menu" className="menu pb-0">
        <div className="container aos-init aos-animate" data-aos="fade-up">
          <div className="section-header mb-0">
            <h2>Our Food Menu</h2>
          </div>

          <div className="mb-3 mt-4">
            <ul
              className="nav nav-tabs d-flex justify-content-between mb-4 aos-init aos-animate"
              data-aos="fade-up"
              data-aos-delay="200"
              id="parentTabs"
              role="tablist"
            >
              <li className="nav-item" role="presentation">
                <a
                  className="nav-link active show"
                  id="tab1-tab"
                  data-bs-toggle="tab"
                  href="#tab1"
                  aria-selected="true"
                  role="tab"
                >
                  <img
                    src={Tab1}
                    className="img-fluid "
                    alt=""
                    data-aos="zoom-out"
                    data-aos-delay="300"
                  />
                  <h4>Appetizers</h4>
                </a>
              </li>
              <li className="nav-item" role="presentation">
                <a
                  className="nav-link"
                  id="tab2-tab"
                  data-bs-toggle="tab"
                  href="#tab2"
                  aria-selected="false"
                  tabIndex="-1"
                  role="tab"
                >
                  <img
                    src={Tab2}
                    className="img-fluid "
                    alt=""
                    data-aos="zoom-out"
                    data-aos-delay="300"
                  />
                  <h4>Entrees</h4>
                </a>
              </li>
              <li className="nav-item" role="presentation">
                <a
                  className="nav-link"
                  id="tab3-tab"
                  data-bs-toggle="tab"
                  href="#tab3"
                  aria-selected="false"
                  tabIndex="-1"
                  role="tab"
                >
                  <img
                    src={Tab3}
                    className="img-fluid "
                    alt=""
                    data-aos="zoom-out"
                    data-aos-delay="300"
                  />
                  <h4>Bread/Rice</h4>
                </a>
              </li>
              <li className="nav-item" role="presentation">
                <a
                  className="nav-link"
                  id="tab4-tab"
                  data-bs-toggle="tab"
                  href="#tab4"
                  aria-selected="false"
                  tabIndex="-1"
                  role="tab"
                >
                  <img
                    src={Tab4}
                    className="img-fluid "
                    alt=""
                    data-aos="zoom-out"
                    data-aos-delay="300"
                  />
                  <h4>Dessert</h4>
                </a>
              </li>
            </ul>

            <div className="tab-content nested-tab mt-2 d-flex justify-content-center">
              <div
                className="tab-pane fade show active"
                id="tab1"
                role="tabpanel"
                aria-labelledby="tab1-tab"
              >
                <ul
                  className="nav nav-tabs justify-content-center"
                  id="childTabs"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <a
                      className="nav-link active "
                      id="subTab1-tab"
                      data-bs-toggle="tab"
                      href="#subTab1"
                      aria-selected="true"
                      role="tab"
                      onClick={() => setTab("tab1")}
                    >
                      <div className="header-border d-flex me-4">
                        <img src={TabHeader} />
                        <div>
                          <p className="mb-1">Full Tray</p>
                          <h3>$130</h3>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li className="nav-item" role="presentation">
                    <a
                      className="nav-link "
                      id="subTab2-tab"
                      data-bs-toggle="tab"
                      href="#subTab1"
                      aria-selected="false"
                      tabIndex={-1}
                      role="tab"
                      onClick={() => setTab("tab")}
                    >
                      <div className="header-border d-flex me-4">
                        <img src={TabHalfHeader} />
                        <div>
                          <p className="mb-1">Half Tray</p>
                          <h3>$70</h3>
                        </div>
                      </div>
                    </a>
                  </li>
                </ul>
                <div className="tab-content mt-2">
                  <div
                    className="tab-pane fade show active"
                    id="subTab1"
                    role="tabpanel"
                    aria-labelledby="subTab1-tab"
                  >
                    <div className="row gy-5">
                      {/* For full tray appetisers */}
                      {order
                        .filter((item) => item.category === "APPETISERS")
                        .map((item, index) => (
                          <Card
                            key={item.id}
                            data={item}
                            index={index}
                            setOrder={setOrder}
                            order={order}
                            setCart={setCart}
                            cart={cart}
                          />
                        ))}
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="subTab2"
                    role="tabpanel"
                    aria-labelledby="subTab2-tab"
                  >
                    <div className="row gy-5">
                      {/* For half tray appetisers */}
                      {order
                        .filter((item) => item.category === "APPETISERS")
                        .map((item) => (
                          <Card
                            key={item.id}
                            data={item}
                            setOrder={setOrder}
                            order={order}
                            setCart={setCart}
                            cart={cart}
                          />
                        ))}
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="tab-pane fade"
                id="tab2"
                role="tabpanel"
                aria-labelledby="tab2-tab"
              >
                <ul
                  className="nav nav-tabs justify-content-center"
                  id="childTabs2"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <a
                      className="nav-link active"
                      id="subTab3-tab"
                      data-bs-toggle="tab"
                      href="#subTab3"
                      aria-selected="true"
                      role="tab"
                      onClick={() => setTab1("subTab3")}
                    >
                      <div className="header-border d-flex me-4">
                        <img src={TabHeader} />
                        <div>
                          <span className="small">Vegetable</span>
                          <p className="mb-1">Full Tray</p>
                          <h3>$130</h3>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li className="nav-item" role="presentation">
                    <a
                      className="nav-link"
                      id="subTab4-tab"
                      data-bs-toggle="tab"
                      href="#subTab3"
                      aria-selected="false"
                      tabIndex={-1}
                      role="tab"
                      onClick={() => setTab1("subTab4")}
                    >
                      <div className="header-border d-flex me-4">
                        <img src={TabHalfHeader} />
                        <div>
                          <span className="small">Vegetable</span>
                          <p className="mb-1">Half Tray</p>
                          <h3>$70</h3>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li className="nav-item" role="presentation">
                    <a
                      className="nav-link"
                      id="subTab5-tab"
                      data-bs-toggle="tab"
                      href="#subTab3"
                      aria-selected="false"
                      tabIndex={-1}
                      role="tab"
                      onClick={() => setTab1("subTab5")}
                    >
                      <div className="header-border d-flex me-4">
                        <img src={TabHeader} />
                        <div>
                          <span className="small">Paneer</span>
                          <p className="mb-1">Full Tray</p>
                          <h3>$150</h3>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li className="nav-item" role="presentation">
                    <a
                      className="nav-link"
                      id="subTab6-tab"
                      data-bs-toggle="tab"
                      href="#subTab3"
                      aria-selected="false"
                      tabIndex={-1}
                      role="tab"
                      onClick={() => setTab1("subTab6")}
                    >
                      <div className="header-border d-flex me-4">
                        <img src={TabHalfHeader} />
                        <div>
                          <span className="small text-left">Paneer</span>
                          <p className="mb-1">Half Tray</p>
                          <h3>$80</h3>
                        </div>
                      </div>
                    </a>
                  </li>
                </ul>
                <div className="tab-content mt-2">
                  <div
                    className="tab-pane fade show active"
                    id="subTab3"
                    role="tabpanel"
                    aria-labelledby="subTab3-tab"
                  >
                    <div className="row gy-5">
                      {/* For full tray ENTREES */}
                      {order
                        .filter((item) => item.category === "ENTREES")
                        .map((item) => (
                          <Card
                            key={item.id}
                            data={item}
                            setOrder={setOrder}
                            order={order}
                            setCart={setCart}
                            cart={cart}
                          />
                        ))}
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="subTab4"
                    role="tabpanel"
                    aria-labelledby="subTab4-tab"
                  >
                    <div className="row gy-5">
                      {/* For half tray ENTREES */}
                      {order
                        .filter((item) => item.category === "ENTREES")
                        .map((item) => (
                          <Card
                            key={item.id}
                            data={item}
                            setOrder={setOrder}
                            order={order}
                            setCart={setCart}
                            cart={cart}
                          />
                        ))}
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="subTab4"
                    role="tabpanel"
                    aria-labelledby="subTab5-tab"
                  >
                    <div className="row gy-5">
                      {/* For full tray ENTREES */}
                      {order
                        .filter((item) => item.category === "ENTREES")
                        .map((item) => (
                          <Card
                            key={item.id}
                            data={item}
                            setOrder={setOrder}
                            order={order}
                            setCart={setCart}
                            cart={cart}
                          />
                        ))}
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="subTab6"
                    role="tabpanel"
                    aria-labelledby="subTab6-tab"
                  >
                    <div className="row gy-5">
                      {/* For half tray ENTREES */}
                      {order
                        .filter(
                          (item) =>
                            item.category === "ENTREES" &&
                            item.type === "PANEER"
                        )
                        .map((item) => (
                          <Card
                            key={item.id}
                            data={item}
                            setOrder={setOrder}
                            order={order}
                            setCart={setCart}
                            cart={cart}
                          />
                        ))}
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="tab3"
                role="tabpanel"
                aria-labelledby="tab3-tab"
              >
                <ul
                  className="nav nav-tabs justify-content-center"
                  id="childTabs2"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <a
                      className="nav-link active"
                      id="subTab9-tab"
                      data-bs-toggle="tab"
                      href="#subTab9"
                      aria-selected="true"
                      role="tab"
                      onClick={() => setTab3("subTab9")}
                    >
                      <div className="header-border d-flex me-4">
                        <img src={TabHeader} />
                        <div>
                          {/* <p className="mb-1">Full Tray</p> */}
                          <h3 className="pt-3 pb-3">Full Tray</h3>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li className="nav-item" role="presentation">
                    <a
                      className="nav-link"
                      id="subTab8-tab"
                      data-bs-toggle="tab"
                      href="#subTab8"
                      aria-selected="false"
                      tabIndex={-1}
                      role="tab"
                      onClick={() => setTab3("subTab8")}
                    >
                      <div className="header-border d-flex me-4">
                        <img src={TabHalfHeader} />
                        <div>
                          {/* <p className="mb-1">Half Tray</p> */}
                          <h3 className="pb-3 pt-3">Half Tray </h3>
                        </div>
                      </div>
                    </a>
                  </li>
                </ul>
                {/*                 
                <div className="tab-content mt-2">
                  <div className="tab-pane fade show active" id="subTab10">
                    <div className="row gy-5">
                     
                      {order
                        .filter((item) => item.category === "BREAD/RICE")
                        .map((item) => (
                          <Card
                            key={item.id}
                            data={item}
                            setOrder={setOrder}
                            order={order}
                            setCart={setCart}
                            cart={cart}
                          />
                        ))}
                    </div>
                  </div>
                  <div className="tab-pane fade show active" id="subTab10">
                    <div className="row gy-5">
                    
                      {order
                        .filter((item) => item.category === "BREAD/RICE")
                        .map((item) => (
                          <Card
                            key={item.id}
                            data={item}
                            setOrder={setOrder}
                            order={order}
                            setCart={setCart}
                            cart={cart}
                          />
                        ))}
                    </div>
                  </div>
                </div>
                 */}

                <div className="tab-content mt-2">
                  <div
                    className="tab-pane fade show active"
                    id="subTab9"
                    role="tabpanel"
                    aria-labelledby="subTab9-tab"
                  >
                    <div className="row gy-5">
                      {/* For half tray ENTREES */}
                      {order
                        .filter((item) => item.category === "BREAD/RICE")
                        .map((item) => (
                          <Card
                            key={item.id}
                            data={item}
                            setOrder={setOrder}
                            order={order}
                            setCart={setCart}
                            cart={cart}
                          />
                        ))}
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="subTab8"
                    role="tabpanel"
                    aria-labelledby="subTab8-tab"
                  >
                    <div className="row gy-5">
                      {/* For full tray ENTREES */}
                      {order
                        .filter((item) => item.category === "BREAD/RICE")
                        .map((item) => (
                          <Card
                            key={item.id}
                            data={item}
                            setOrder={setOrder}
                            order={order}
                            setCart={setCart}
                            cart={cart}
                          />
                        ))}
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="tab4"
                role="tabpanel"
                aria-labelledby="tab4-tab"
              >
                <ul
                  className="nav nav-tabs justify-content-center"
                  id="childTabs2"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <a
                      className="nav-link active"
                      id="subTab7-tab"
                      data-bs-toggle="tab"
                      href="#subTab7"
                      aria-selected="true"
                      role="tab"
                      onClick={() => setTab2("subTab7")}
                    >
                      <div className="header-border d-flex me-4">
                        <img src={TabHeader} />
                        <div>
                          <p className="mb-1">Full Tray</p>
                          <h3>$110</h3>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li className="nav-item" role="presentation">
                    <a
                      className="nav-link"
                      id="subTab10-tab"
                      data-bs-toggle="tab"
                      href="#subTab10"
                      aria-selected="false"
                      tabIndex={-1}
                      role="tab"
                      onClick={() => setTab2("subTab10")}
                    >
                      <div className="header-border d-flex me-4">
                        <img src={TabHalfHeader} />
                        <div>
                          <p className="mb-1">Half Tray</p>
                          <h3>$60</h3>
                        </div>
                      </div>
                    </a>
                  </li>
                </ul>
                <div className="tab-content mt-2">
                  <div
                    className="tab-pane fade show active"
                    id="subTab10"
                    role="tabpanel"
                    aria-labelledby="subTab10-tab"
                  >
                    <div className="row gy-5">
                      {/* For half tray bread and rice */}
                      {order
                        .filter((item) => item.category === "DESSERT")
                        .map((item) => (
                          <Card
                            key={item.id}
                            data={item}
                            setOrder={setOrder}
                            order={order}
                            setCart={setCart}
                            cart={cart}
                          />
                        ))}
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="subTab7"
                    role="tabpanel"
                    aria-labelledby="subTab7-tab"
                  >
                    <div className="row gy-5">
                      {/* For half tray bread and rice */}
                      {order
                        .filter((item) => item.category === "DESSERT")
                        .map((item) => (
                          <Card
                            key={item.id}
                            data={item}
                            setOrder={setOrder}
                            order={order}
                            setCart={setCart}
                            cart={cart}
                          />
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="warning mt-5">
            <div className="d-flex">
              <div>
                <img src={Warning} className="me-3" />
              </div>
              <div>
                <p className="mb-0">
                  <strong>Food Allergy Warning</strong>
                </p>
                <p>
                  Please be advised that our food may have come in contact or
                  contain peanuts, tree nuts, soy, milk, eggs, wheat.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <PlaceOrderSection
        cart={cart}
        setCart={setCart}
        subTotal={subTotal}
        total={total}
        onUpdateQuantity={onUpdateQuantity}
        onSelectChange={onSelectChange}
        onDelete={onDelete}
        onRefresh={onRefresh}
      />
    </>
  );
}

export default Menu;
