import React, { useState } from "react";
import Comingsoon from "../img/comingsoon.png";
import Formbg from "../img/form-bg.svg";
import s1 from "../img/1.svg";
import s2 from "../img/3.svg";
import s3 from "../img/2.svg";
import emailjs from "emailjs-com";
import axios from "axios";

const SubscriptionSection = () => {
  const [formData, setFormData] = useState({ email: "", phone: "" });
  const [subscribed, setSubscribed] = useState(false);
  const [errors, setErrors] = useState({});

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.email.trim() && !formData.phone.trim()) {
      newErrors.email = "Email or phone number is required.";
      newErrors.phone = "Email or phone number is required.";
    } else if (
      !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email.trim()) &&
      formData.email.trim()
    ) {
      newErrors.email = "Invalid email address";
    } else if (
      !/^\d{10}$/.test(formData.phone.trim()) &&
      formData.phone.trim()
    ) {
      newErrors.phone = "Invalid phone number";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent default form submission behavior
    try {
      if (validateForm()) {
        const serviceId = "service_cuge3hu";
        const templateId = "template_fdc8rwa";
        const userId = "8TmY4BMSBWWBYxraj";

        const emailData = {
          to_email: formData.email,
          from_name: "9Spices",
          subject: "Email subscription",
          email: formData.email,
          phoneNumber: formData.phone,
        };

        // Send the email using Email.js
        const response = await axios.post(
          "https://be.9spices.com/subscribe",
          emailData
        );

        alert(
          "🌟 Thank you for gracing nineSpices! Your subscription elevates our culinary journey. Get ready for an exquisite adventure! 🍽️ "
        );
        setFormData({ email: "", phone: "" });
        setSubscribed(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <section id="subscription" className="book-a-table mt-0 pt-0 pb-0">
      <div className="row g-0">
        <div className="col-lg-6 align-items-center reservation-form-bg">
          <img src={Comingsoon} alt="" className="img-fluid" />
          <img src={Formbg} alt="" className="img-fluid" />
        </div>

        <div className="col-lg-6 reservation-img">
          <h2>Monthly Meal Subscriptions</h2>
          {subscribed ? (
            <p className="mt-0">Thank you for subscribing!</p>
          ) : (
            <p className="mt-0">
              Secure your spot now for an exclusive taste adventure
              <br /> — coming very soon
            </p>
          )}

          <div className="info-item d-flex align-items-center mb-3">
            <img src={s1} alt="" className="img-fluid" />
            <div>
              <h4>
                Month basis subscription with a minimum of{" "}
                <span>10 meals a month</span>
              </h4>
            </div>
          </div>
          <div className="info-item d-flex align-items-center mb-3">
            <img src={s2} alt="" className="img-fluid" />
            <div>
              <h4>
                Choose from <span>30+ recipes</span>
              </h4>
            </div>
          </div>
          <div className="info-item mb-5 d-flex align-items-center">
            <img src={s3} alt="" className="img-fluid" />
            <div>
              <h4>
                First 100 customers will get a discount of{" "}
                <span>20% on the subscription</span> for 3 months
              </h4>
            </div>
          </div>
          <form className="mt-5 mb-3" onSubmit={handleSubmit}>
            <div className="d-flex align-items-center ">
              <div className="form-floating mb-3 me-3">
                <input
                  type="email"
                  className={`form-control  ${
                    errors.email ? "is-invalid" : ""
                  }`}
                  id="floatingInput1"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                />
                <label htmlFor="floatingInput">Email ID</label>
                {errors.email && (
                  <div className="invalid-feedback">{errors.email}</div>
                )}
              </div>
              {/* <span
                style={{
                  display: "inline-block",
                  margin: "10px 10px 10px 0px",
                }}
              >
                OR
              </span>
            */}
              <div className="form-floating mb-3">
                <button type="submit" className="btn form-btn">
                  Subscribe
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default SubscriptionSection;
