import React from "react";

function CartCard({ item, onUpdateQuantity, onSelectChange, onDelete }) {
  const handleQuantityChange = (e, trayType) => {
    onUpdateQuantity(item.id, parseInt(e.target.value, 10), trayType);
  };

  const handleSelectChange = (e) => {
    onSelectChange(item.id, e.target.value);
  };

  const handleDelete = () => {
    onDelete(item.id);
  };

  return (
    <div className="item-list mb-2">
      <div className="d-flex justify-content-between">
        <h4>{item.name}</h4>
        {item.isEachable ? (
          <div className="input" style={{ width: "20%" }}>
            <label htmlFor="fullTray" className="form-label">
              Full : ${item.fullPrice}
            </label>
            <input
              type="number"
              className="form-control form1"
              aria-describedby="basic-addon-minus"
              min="0"
              value={item.fullTrayQuantity}
              onChange={(e) => handleQuantityChange(e, "fullTray")}
            />
          </div>
        ) : (
          <>
            <div className="input-group" style={{ width: "20%" }}>
              <label htmlFor="fullTray" className="form-label">
                Full : ${item.fullPrice}
              </label>
              <input
                type="number"
                className="form-control"
                aria-describedby="basic-addon-minus"
                min="0"
                value={item.fullTrayQuantity}
                onChange={(e) => handleQuantityChange(e, "fullTray")}
              />
            </div>
            <div className="input-group" style={{ width: "20%" }}>
              <label htmlFor="fullTray" className="form-label">
                Half : ${item.halfPrice}
              </label>
              <input
                type="number"
                className="form-control"
                aria-describedby="basic-addon-minus"
                min="0"
                value={item.halfTrayQuantity}
                onChange={(e) => handleQuantityChange(e, "halfTray")}
              />
            </div>
          </>
        )}
      </div>

      <div className="d-flex justify-content-between">
        <p>
          <button
            className="btn btn-link small cursor text-dark"
            onClick={handleDelete}
          >
            Delete
          </button>
        </p>
        <p>
          $
          {item.fullTrayQuantity * item.fullPrice +
            item.halfTrayQuantity * item.halfPrice}
        </p>
      </div>
    </div>
  );
}

export default CartCard;
