import React, { useEffect, useState } from "react";
import HeroImag from "../img/hero-imgg.png";
import One from "../img/1.png";
import Two from "../img/2.png";
import Three from "../img/3.png";
import SubscriptionSection from "../components/subscribe";
import Notes from "../components/note";
import Events from "../components/Slidersection";
import EventsSection from "../components/Slidersection";
import PlaceOrderSection from "../components/Placeorder";
import Menu from "../components/menu";
import data from "../items.json";
import Headerimg from "../img/header-btn.png";

function HomePage() {
  return (
    <div>
      <section id="hero" className="hero align-items-center">
        <div className="container">
          <div className="row justify-content-between gy-5 mt-4 mb-5 pb-3">
            <div className="col-lg-6 order-2 order-lg-1 d-flex flex-column justify-content-center align-items-center align-items-lg-start text-center text-lg-start">
              <h2 data-aos="fade-up">Indulge in authentic Indian flavors!</h2>
              <a className="header-btn" href="#book-a-table">
                <div className="d-flex">
                  <div>
                    <img src={Headerimg} className="img-fluid me-3" />
                  </div>
                  <div className="ml-5">
                    {" "}
                    <p className="mb-0">Indian cuisine</p>
                    Order at order@9spices.com
                  </div>
                </div>
              </a>
            </div>
            <div className="col-lg-5 offset-lg-1 order-1 order-lg-2 text-center text-lg-start">
              <img
                src={HeroImag}
                className="img-fluid hero-img"
                alt=""
                data-aos="zoom-out"
                data-aos-delay="300"
              />
            </div>
          </div>
        </div>
      </section>
      <div id="about" className="p-4">
        <div id="" className="why-us">
          <div className="container" data-aos="fade-up">
            <h2>What we bring to the table</h2>
            <div className="col-lg-12 d-flex align-items-center">
              <div className="row gy-4 mb-3">
                <div
                  className="col-xl-4"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <div className="icon-box">
                    <img
                      src={One}
                      className="img-fluid"
                      alt=""
                      data-aos="zoom-out"
                      data-aos-delay="300"
                    />

                    <h4>Authentic Indian Cuisine Experience</h4>
                    <p>
                      Immerse yourself in the vibrant and diverse tastes of
                      India with Nine Spices, specializing in bringing the
                      authentic flavors of Indian cuisine to your events.
                    </p>
                  </div>
                </div>
                <div
                  className="col-xl-4"
                  data-aos="fade-up"
                  data-aos-delay="300"
                >
                  <div className="icon-box">
                    <img
                      src={Two}
                      className="img-fluid "
                      alt=""
                      data-aos="zoom-out"
                      data-aos-delay="300"
                    />

                    <h4>Expert Culinary Craftsmanship</h4>
                    <p>
                      Our dedicated team of expert chefs crafts exquisite dishes
                      that reflect India's rich culinary heritage, offering a
                      memorable experience with traditional curries and a
                      diverse menu.
                    </p>
                  </div>
                </div>
                <div
                  className="col-xl-4"
                  data-aos="fade-up"
                  data-aos-delay="400"
                >
                  <div className="icon-box">
                    <img
                      src={Three}
                      className="img-fluid "
                      alt=""
                      data-aos="zoom-out"
                      data-aos-delay="300"
                    />

                    <h4>Versatile Event Catering</h4>
                    <p>
                      Whether it's a corporate event, wedding, birthday
                      celebration, or a special gathering, Nine Spices is
                      committed to impressing your guests and delighting their
                      senses with a carefully curated culinary journey.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <h2 className="mt-4 mb-5">Why Nine Spices</h2>
          </div>
        </div>
      </div>

      <EventsSection />
      <Menu />
      <Notes />
      <SubscriptionSection />
    </div>
  );
}

export default HomePage;
