import React, { useEffect, useState } from "react";

function Card({ data, setOrder, index, order, cart, setCart }) {
  const [item, setItem] = useState({
    ...data,
    fullTrayQuantity: data.fullTrayQuantity || 0,
    halfTrayQuantity: data.halfTrayQuantity || 0,
  });
  const [checked, setChecked] = useState(data.isSelected);

  const handleChange = (e, trayType) => {
    const newQuantity = e.target.value;
    const newItem = {
      ...item,
      [`${trayType}Quantity`]: newQuantity,
      isSelected: true,
    };
    setItem(newItem);

    // Check if the item already exists in the cart
    const existingItemIndex = cart.findIndex(
      (cartItem) => cartItem.id === newItem.id
    );

    if (Number(newQuantity) > 0 && newItem.isSelected) {
      if (existingItemIndex !== -1) {
        // If the item exists, update the quantity
        const updatedCart = [...cart];
        updatedCart[existingItemIndex][`${trayType}Quantity`] = newQuantity;
        setCart(updatedCart);
      } else {
        // If the item doesn't exist, add it to the cart
        setCart([...cart, newItem]);
      }
    } else {
      // Remove the item from the cart if isSelected is false or quantity is 0
      setCart(cart.filter((cartItem) => cartItem.id !== newItem.id));
    }
  };

  const HandleCheckFunc = (e) => {
    const newItem = { ...item, isSelected: e.target.checked };
    setItem(newItem);
    setChecked(e.target.checked);

    // Check if the item already exists in the cart
    const existingItemIndex = cart.findIndex(
      (cartItem) => cartItem.id === newItem.id
    );

    if (Number(item.fullTrayQuantity) > 0 && item.isSelected) {
      if (existingItemIndex !== -1) {
        // If the item exists, update the isSelected value
        const updatedCart = [...cart];
        updatedCart[existingItemIndex].isSelected = e.target.checked;
        setCart(updatedCart);
      } else {
        // If the item doesn't exist, add it to the cart
        setCart([...cart, newItem]);
      }
    } else {
      // Remove the item from the cart if isSelected is false or quantity is 0
      setCart(cart.filter((cartItem) => cartItem.id !== newItem.id));
    }
  };

  return (
    <div className="col-lg-4 ">
      <div className="menu-item">
        <div className="d-flex">
          <div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                id="punjabiSamosaCheckbox"
                onChange={HandleCheckFunc}
                checked={item.isSelected}
              />
            </div>
          </div>
          {
            <div>
              <h4>{item.name}</h4>
              {item.isEachable === true ? (
                <div>
                  {" "}
                  <p className="ingredients ">
                    Price per piece ${item.fullPrice}
                  </p>
                  <p className="price1">{item.description}</p>
                </div>
              ) : item.category === "BREAD/RICE" ? (
                // <p className="ingredients">Price ${item.price}</p>
                <p className="price">{item.description}</p>
              ) : (
                <p className="price">{item.description}</p>
              )}
            </div>
          }
        </div>

        <div className="d-flex" style={{ alignItems: "stretch" }}>
          {item.isEachable ? (
            <div className="input-group ms-4 mb-3">
              <label htmlFor="fullTray" className="form-label">
                Quantity:
              </label>
              <input
                type="number"
                id="fullTray"
                className="form-control"
                style={{
                  WebkitAppearance: "none",
                  MozAppearance: "textfield",
                  color: "#C12F2E",
                }}
                aria-describedby="basic-addon-minus"
                min={0}
                onChange={(e) => handleChange(e, "fullTray")}
                value={item.fullTrayQuantity}
              />
            </div>
          ) : (
            <>
              <div className="input-group ms-4 mb-3">
                <label htmlFor="fullTray" className="form-label">
                  Full Tray:
                </label>
                <input
                  type="number"
                  id="fullTray"
                  className="form-control"
                  style={{
                    WebkitAppearance: "none",
                    MozAppearance: "textfield",
                    color: "#C12F2E",
                  }}
                  aria-describedby="basic-addon-minus"
                  min={0}
                  onChange={(e) => handleChange(e, "fullTray")}
                  value={item.fullTrayQuantity}
                />
              </div>
              <div className="input-group ms-4 mb-3">
                <label htmlFor="halfTray" className="form-label">
                  Half Tray:
                </label>
                <input
                  type="number"
                  id="halfTray"
                  className="form-control"
                  style={{
                    WebkitAppearance: "none",
                    MozAppearance: "textfield",
                    color: "#C12F2E",
                  }}
                  aria-describedby="basic-addon-minus"
                  min={0}
                  onChange={(e) => handleChange(e, "halfTray")}
                  value={item.halfTrayQuantity}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default Card;
